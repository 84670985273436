import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/utilities/seo'
import indexPageStyles from './index-page.module.scss'

import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import BouncingCoins from '../components/bouncing_coins';
import Parties from '../components/parties';
import About from '../components/about';
import SubmitParty from '../components/submit_party';
import Nav from '../components/nav';
import Countdown from '../components/countdown';
import Badge from '../components/badge';

const IndexPage = () => {
    return (
        <ParallaxProvider>
            <div className="container">
                {/* <BouncingCoins /> */}
                <Nav/>
            </div>
                
            <div className="container">
                <div>
                    <Badge/>
                </div>
            </div>
                
            {/* <Countdown/> */}

            <div className="section-white">
                <div className="container">
                    <div className="pad-inside" style={{paddingTop: `1rem`}}>
                        <Parties/>
                    </div>
                </div>
            </div>

            <div className="section-colored">
                <div className="container">
                    <div className="pad-inside">
                        <About/>
                    </div>
                </div>
            </div> 

            <div className="section-white">
                <div className="container">
                    <div className="pad-inside">
                        <SubmitParty/>
                    </div>
                </div>
            </div>


            <div className="medium-container">
                <div style={{paddingBottom: `8rem`}}>
                    <a href={"https://t.me/joinchat/AoGu5x0ho5qPDbPQia2hNw"} >
                        <strong><i>For question or to suggest edits to current events contact us on telegram here</i></strong>
                    </a>
                </div>
                <div style={{textAlign: `center`, fontSize: `60%`, marginBottom: `1rem`}}>Bitcoin halving logo by @phneep</div>
            </div>

            <SEO title="Home" />
        </ParallaxProvider>
    )
}

export default IndexPage;