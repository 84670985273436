import React from "react"

import { Pie, Chart } from "react-chartjs-2"
Chart.defaults.global.animation.duration = 2000
Chart.defaults.global.animation.easing = "easeOutQuad"
Chart.defaults.global.defaultFontColor = "black"
Chart.defaults.global.legend.display = false

const data = {
  labels: [
    "2009",
    "2013",
    "2017",
    "2020",
    "2024",
    "2028",
    "2032",
    "2036",
    "2040",
    "2044",
    "2048",
    "2052",
  ],
  datasets: [
    {
      data: [
        50.0,
        25.0,
        12.5,
        6.25,
        3.125,
        1.5625,
        0.78125,
        0.390625,
        0.1953125,
        0.09765625,
        0.048828125,
        0.0244140625,
      ],
      backgroundColor: [
        "#f2a900",
        "#f5be40",
        "#f2a900",
        "#f5be40",
        "#f2a900",
        "#f5be40",
        "#f2a900",
        "#f5be40",
        "#f2a900",
        "#f5be40",
        "#f5be40",
        "#f5be40",
      ],
    },
  ],
}

const PieChart = () => <Pie options={{ maintainAspectRatio: false }} data={data}/>

export default PieChart


