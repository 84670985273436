import React from 'react';
import CollapsibleTable from './table';
import partiesStyles from './parties.module.scss';

const Parties = () => {
    return(
        <div id="parties" className={partiesStyles.parties}>
            <h2 className={partiesStyles.title}>List of 2020 Halving Events</h2>
            <CollapsibleTable />
        </div>
    )
}

export default Parties;