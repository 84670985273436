import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import partiesStyles from './parties.module.scss';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    },
  },
});


class YouTube extends React.PureComponent {
  componentDidMount = () => {
    // On mount, check to see if the API script is already loaded

    if (!window.YT) { // If not, load the script asynchronously
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = this.loadVideo;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    } else { // If script is already there, load the video directly
      this.loadVideo();
    }
  };

  loadVideo = () => {
    console.log('video', this.props)
    const id = this.props.url.split('=')[1];

    // the Player object is created uniquely based on the id in props
    this.player = new window.YT.Player(`youtube-player-${id}`, {
      videoId: id,
      events: {
        onReady: this.onPlayerReady,
      },
    });
  };

  onPlayerReady = event => {
    event.target.playVideo();
  };

  render = () => {
    const id = this.props.url.split('=')[1];
    return (
      <div>
        <div id={`youtube-player-${id}`}/>
      </div>
    );
  };
}

function Row(props) {
  console.log('row', props);
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.event_name}
        </TableCell>
        <TableCell align="left">{row.organizer}</TableCell>
        <TableCell align="left">{row.platform}</TableCell>
        <TableCell align="left">{row.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className={partiesStyles.eventStream}>
              <Box margin={1}>
                <div className={partiesStyles.eventFrame}>
                  {row.history && row.history.map((historyRow, index) => {
                    if (historyRow.live_stream_url !== undefined && historyRow.live_stream_url.length > 1) {
                      return(<YouTube url={historyRow.live_stream_url}/>)
                    } else {
                      return (<img src={historyRow.logo}/>)
                    }
                  })}
                </div>
              </Box>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Event details
                </Typography>
                <p>
                  <strong>Start time:</strong> {row.start_time}
                </p>
                <p>
                  <strong>Source:</strong> <a href={row.event_url}>{row.event_url !== undefined ? 'Visit Event Page' : 'N/A'}</a>
                </p>
                <p>
                  <strong>Announcement:</strong> <a href={row.announcement_url}>{row.announcement_url !== undefined ? 'Visit Announcement Page' : 'N/A'}</a>
                </p>
              </Box>
          </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    event_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        }),
      ).isRequired,
  }).isRequired,
};

function createData({id, event_name, event_url, organizer, platform, country, rsvp, announcement_url, description, end_time, start_time, guest_speakers, live_stream_url, logo}) {
  // console.log(start_time, end_time)

  if (start_time === undefined) {
    start_time = 'N/A';
  } else {
    start_time = new Date(start_time);
    start_time = `${start_time.toDateString()} @ ${start_time.getHours()} : ${start_time.getMinutes()}`;
  }
  if (end_time === undefined) {
    end_time = 'N/A';
  } else {
    end_time = new Date(end_time);
    end_time = `${end_time.toDateString()} @ ${end_time.getHours()} : ${end_time.getMinutes()}`;
  }

  return {
    id,
    event_name,
    organizer,
    platform,
    guest_speakers,
    country,
    start_time,
    end_time,
    rsvp,
    description,
    announcement_url,
    live_stream_url,
    event_url,
    logo,
    history: [
      { end_time: end_time, start_time: start_time, rsvp: rsvp, event_url: event_url, live_stream_url: live_stream_url, annoucement_url: announcement_url, logo: logo, country: country },
    ],
  };
}

function CollapsibleTable() {
  const [rows, setRows] = useState(false);

  useEffect(() => {
    const apiKey = `key9LTXSFo9Me0gy0`;
    const baseUrl = `https://api.airtable.com/v0/appkV8XEKjqSBkpud/events?api_key=${apiKey}`;
    fetch(baseUrl)
    .then(res => res.json())
    .then(data => {
      let rows = []
      if (data.records.length !== 0) {
        // console.log('raw data', data)

        data.records.forEach((record) => {
          console.log('raw record', record);
          let { 
            country,
            id,
            event_name,
            rsvp,
            announcement_url,
            description,
            end_time,
            start_time,
            guest_speakers,
            live_stream_url,
            logo,
            organizer,
            platform,
            event_url,
            verified
          } = record.fields;


          if (verified === true) {
            // start_time = new Date(start_time);
            // if (start_time > new Date()) {
              rows.push(createData(record.fields));
            // }
          }
        }); 
      }
      rows = rows.sort((a,b) => a.id - b.id);
      console.log('sorted rows', rows);
      setRows(rows);
    });
  }, []);

  return (
    <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
        <TableHead style={{'font-weight': 800}}>
        <TableRow >
            <TableCell align="left"/>
            <TableCell align="left"><span style={{'font-weight': '800'}} >Event Name</span></TableCell>
            <TableCell align="left"><span style={{'font-weight': '800'}} >Organizer</span></TableCell>
            <TableCell align="left"><span style={{'font-weight': '800'}} >Platform</span></TableCell>
            <TableCell align="left"><span style={{'font-weight': '800'}} >Description</span></TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        { rows && rows.map((row) => (
            <Row key={row.event_name} row={row} />
        ))}
        </TableBody>
    </Table>
    </TableContainer>
  );
}

export default CollapsibleTable;