import { Link, animateScroll as scroll } from "react-scroll";
import React from "react";
import { style } from './style.css';

const Nav = () => (
    <div className='nav-section'>
            {/* <Link 
                activeClass="active"
                to="countdown"
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}
            >Countdown</Link> */}

        <h3>Bitcoin Halving Parties</h3>

        <div className='nav-container'>
            <Link 
                activeClass="active"
                to="parties"
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}
            >Parties</Link>
            <Link 
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}
            >About</Link>
                <Link 
                activeClass="active"
                to="submit-party"
                spy={true}
                smooth={true}
                offset={0}
                duration= {500}
            >Submit Event</Link>
        </div>
    </div>
)   

export default Nav;
