import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import badgeStyles from './badge.module.scss';

const Badge = () => (
    <div className={badgeStyles.badge}>
        <Image/>
    </div>
);

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Bitcoin-Halvening-Axe-and-Coinstack.png" }) {
        childImageSharp {
          fluid(maxWidth: 230) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <div><Img style={{width: '230px'}} fluid={data.placeholderImage.childImageSharp.fluid} /></div>
}

export default Badge;