import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
// needed for older browsers to load Yup
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/es6/map';
import submitPartyStyles from './submit_party.module.scss';

var Airtable = require('airtable');
var base = new Airtable({apiKey: 'key9LTXSFo9Me0gy0'}).base('appkV8XEKjqSBkpud');
console.log('debug', process.env);

let schema = yup.object().shape({
  event_name: yup.string().required(),
  description: yup.string().required(),
  platform: yup.string().required(),
  event_url: yup.string().url()
});

// check validity: This is provided strcitly for devs as an example
schema
  .isValid({
    event_name: 'weird',
    description: 'weird',
    platform: 'weird',
    event_url: 'http://www.something.com'
  })
  .then((valid) => {
    // console.log('Is the form schema valid?', valid)
  });

const ContactForm = () => (
  <Formik
      initialValues={{ event_name: '' }}
      validationSchema={schema}
      validate={values => {
        const errors = {};
        // if (!values.email) {
        //   errors.email = 'Required';
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        // ) {
        //   errors.email = 'Invalid email address';
        // }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          console.log('form data', values);
         
          base('events').create([
            { fields: values }
          ], function(err, records) {
            if (err) {
              console.error(err);
              return;
            }
            records.forEach(function (record) {
              console.log(record.getId());
            });
          });

          alert('Thanks for subitting an event! We will get back in touch with you shortly.');

          setSubmitting(false);
        }, 400);
      }}
    >
    {({ isSubmitting }) => (
      <Form className={submitPartyStyles.form}>
        <label htmlFor="event_name" style={{ display: "block" }}>Event Name</label>
        <Field type="event_name" name="event_name" />
        <ErrorMessage className={submitPartyStyles.errorMessage} name="event_name" component="div" />

        <label htmlFor="description" style={{ display: "block" }}>Description</label>
        <Field type="description" name="description"/>
        <ErrorMessage className={submitPartyStyles.errorMessage} name="description" component="div"/>

        <label htmlFor="platform" style={{ display: "block" }}>Platform</label>
        <Field type="platform" name="platform"/>
        <ErrorMessage className={submitPartyStyles.errorMessage} name="platform" component="div"/>

        <label htmlFor="event_url" style={{ display: "block" }}>Event URL</label>
        <Field type="url" name="event_url"/>
        <ErrorMessage className={submitPartyStyles.errorMessage} name="url" component="div"/>

        <div>
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button> 
        </div>
      </Form>
    )}
    </Formik>
)

export default ContactForm;
