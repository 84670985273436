import React from 'react';
import submitPartyStyles from './submit_party.module.scss';
import ContactForm from './contact_form.js';
import Badge from '../badge';

const SubmitParty = () => (
    <div id="submit-party" className={"medium-container"}>
        {/* <Badge/> */}
        <div>
            <div className={submitPartyStyles.topText}>
                <p className={submitPartyStyles.header}>Want To Get <strong>Listed?</strong> &#128378;</p>
                <p>Send us information about your event and our team will get back to you shortly!</p>
            </div>
            <div className={submitPartyStyles.form}><ContactForm/></div>
        </div>
    </div>
)

export default SubmitParty;