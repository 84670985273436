import React from 'react';
import PieChart from './pie-chart';
import aboutStyles from './about.module.scss';

const About = () => {
    return(
        <div id="about">

          <div>
            <h2 style={{ margin: `2rem`, textAlign: `center` }}>
              Block rewards over time
            </h2>

            <div style={{height: `25rem`}}>
              <PieChart />
            </div>
          </div>

        </div>
    )
};

export default About;