import React, { useEffect } from 'react';
import Matter from 'matter-js';
import btcIcon from '../../images/btc-coin.png';
import bouncingCoinsStyles from './bouncing-coins.module.scss';

const RenderNewBall = (props) => {
  useEffect(() => {
    const interval = setInterval(() => {
      props.addBall();
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="halving-notification"/>
  );
};

class BouncingCoins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var Engine = Matter.Engine,
        Render = Matter.Render,
        MouseConstraint = Matter.MouseConstraint,
        Mouse = Matter.Mouse,
        World = Matter.World,
        Bodies = Matter.Bodies,
        Svg = Matter.Svg,
        Vertices = Matter.Vertices;

    var engine = Engine.create({
      positionIterations: 20
    });

    var render = Render.create({
      element: this.refs.scene,
      engine: engine,
      options: {
        width: (window.innerWidth),
        height: (window.innerHeight), 
        wireframes: false,
        background: 'none',
        pixelRatio: 'auto'
      }
    });
  
    //Browser SVG polyfill for letter B graphic... see: https://github.com/liabru/matter-js/issues/559
    window.decomp = require('poly-decomp');
    var path = '-35 -7 -19 -17 -14 -38 -14 -58 -25 -79 -45 -85 -65 -84 -65 -66 -46 -67 -34 -59 -30 -44 -33 -29 -45 -23 -66 -23 -66 -7 -53 -7';
    var horseShoe = Vertices.scale(Vertices.fromPath(path), 6, 4);
    var topOfLetterB = Bodies.fromVertices(600, 388, horseShoe, {
      isStatic: true,
      render: {
          fillStyle: 'black',
          strokeStyle: 'black',
          lineWidth: 1   
      }
    }, true);
    var bottomOfLetterB = Bodies.fromVertices(600, 660, horseShoe, {
      isStatic: true,
      render: {
          fillStyle: 'black',
          strokeStyle: 'black',
          lineWidth: 1   
      }
    }, true);

    World.add(engine.world, [
      Bodies.rectangle(410, 520, 85, 620, { isStatic: true, render: { fillStyle: 'black', strokeStyle: 'black' } }),
      // Top inner spike
      Bodies.rectangle(435, 190, 35, 55, { isStatic: true, render: { fillStyle: 'black', strokeStyle: 'black' } }),
      // top outer spike
      Bodies.rectangle(540, 210, 35, 90, { isStatic: true, render: { fillStyle: 'black', strokeStyle: 'black' } }),
      // lower inner spike
      Bodies.rectangle(435, 840, 35, 55, { isStatic: true, render: { fillStyle: 'black', strokeStyle: 'black' } }),
      // lower outer spike
      Bodies.rectangle(540, 820, 35, 90, { isStatic: true, render: { fillStyle: 'black', strokeStyle: 'black' } }),
      topOfLetterB,
      bottomOfLetterB
    ]);

    // add mouse control
    // var mouse = Mouse.create(render.canvas),
    //   mouseConstraint = MouseConstraint.create(engine, {
    //     mouse: mouse,
    //     constraint: {
    //       stiffness: 0.2,
    //       render: {
    //         visible: false
    //       }
    //     }
    //   });

    // World.add(engine.world, mouseConstraint);
    // enable ball creation by mouse click by un-comnmenting code below
    // Matter.Events.on(mouseConstraint, "mousedown", () => addBall());

    const addBall = () => {
      World.add(engine.world, Bodies.circle(500, 0, 30, 
        { 
          restitution: 0.9, 
          friction: 0.00000001,
          density: 0.001, 
          render: { 
              sprite: { 
                  xScale: 0.1, 
                  yScale: 0.1, 
                  texture: btcIcon
              }
          } 
        })
      );
    }

    Engine.run(engine);

    Render.run(render);

    this.setState({render: render, addBall: addBall});
  }

  render() {
    return(
      <div className={bouncingCoinsStyles.container}>
        <div ref="scene" />
        { this.state.render && 
          <RenderNewBall world={this.state.render.engine.world} addBall={this.state.addBall}/> 
        }
      </div>
    );
  }
}
export default BouncingCoins;